export enum LanyardSocketEvents {
  initState = 'INIT_STATE',
  presenceUpdate = 'PRESENCE_UPDATE',
}

export enum Activities {
  Playing,
  Streaming,
  Listening,
  Watching,
  Custom,
  Competing,
}
